import React from 'react';

import { AppBar, Toolbar, Typography, Container } from '@material-ui/core';

function CustomFooter() {
    return (
        <AppBar position="static" color="primary">
            <Container maxWidth="md">
                <Toolbar>
                    <Typography variant="h6" color="inherit">
                        &copy; 2010 InfoDraft Technologies
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default CustomFooter;