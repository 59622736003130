import React from 'react';

import { Container } from '@material-ui/core';

import MainSwitch from './components/MainSwitch';

import CustomHero from './components/CustomHero';
// import CustomContactForm from './components/CustomContactForm';
import CustomFooter from './components/CustomFooter';

//import HookFormContactForm from './components/HookFormContactForm';

//import ButtonAppBar from './layout/ButtonAppBar';
import ButtonAppBarTempDrawer from './layout/ButtonAppBarTempDrawer';

function App() {
  return (
      <React.Fragment>
        <ButtonAppBarTempDrawer />
        <CustomHero />
        <Container>
          <div className="page-content" />
          <MainSwitch />
        </Container>
        
        <CustomFooter />
      </React.Fragment>  
  );
}

export default App;
