import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Hosting from '../pages/Hosting';

import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import AccountBox from '@material-ui/icons/AccountBox';
import CloudIcon from '@material-ui/icons/Cloud';

export default [
    {
        id: 1,
        path: '/',
        sidebarName: 'Home',
        icon: HomeIcon,
        component: Home
    },
    {
        id: 2,
        path: '/about',
        sidebarName: 'About',
        icon: InfoIcon,
        component: About
    },
    {
        id: 3,
        path: '/contact',
        sidebarName: 'Contact',
        icon: AccountBox,
        component: Contact
    },
    {
        id: 4,
        path: '/hosting',
        sidebarName: 'Hosting',
        icon: CloudIcon,
        component: Hosting
    }
];