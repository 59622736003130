
import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
  }));

  const tiers = [
    {
      title: 'Starter',
      price: '5',
      description: [
          '1 Website',
          '5 GB of Disk Space',
          '2 Databases',
          '5 Email Accounts',
          '2 Subdomains',
          'Free and Auto SSL',
          'Free Nightly Backups',
          'SSD Raid 10'
      ],
      buttonText: 'Get Started',
      buttonVariant: 'outlined',
      subject: 'Basic',
      hostpage: 'https://billing.infodatahosting.com/cart.php?a=add&pid=1',
    },
    {
      title: 'Plus',
      subheader: 'Most popular',
      price: '10',
      description: [
        '5 Websites',
        '10 GB of storage',
        'Unlimited Databases',
        '30 Email Accounts',
        '5 Subdomains',
        'LiteSpeed Web Server',
        'LiteSpeed Wordpress Cache',
        'Free and Auto SSL',
        'CloudFlare with Railgun',
        'Free Nightly Backups',
        'SSD Raid 10'
      ],
      buttonText: 'Get started',
      buttonVariant: 'outlined',
      subject: 'Pro',
      hostpage: 'https://billing.infodatahosting.com/cart.php?a=add&pid=2',
    },
    {
      title: 'Business',
      price: '30',
      description: [
        'Unlimited Websites',
        '25 GB of storage',
        'Unlimited Databases',
        'Unlimited Email',
        'Unlimited Subdomains',
        'LiteSpeed Web Server',
        'LiteSpeed Wordpress Cache',
        'Free and Auto SSL',
        'CloudFlare with Railgun',
        'Free Nightly Backups',
        'SSD Raid 10'
      ],
      buttonText: 'Get Started',
      buttonVariant: 'outlined',
      subject: 'Enterprise',
      hostpage: 'https://billing.infodatahosting.com/cart.php?a=add&pid=3',
    },
  ];

export default function Hosting() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Hosting Plans
                </Typography>
            </Container>
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                                    className={classes.cardHeader}
                                />
                                <CardContent>
                                    <div className={classes.cardPricing}>
                                        <Typography component="h2" variant="h3" color="textPrimary">
                                            USD {tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            /mo
                                        </Typography>
                                    </div>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button fullWidth variant={tier.buttonVariant} color="primary">
                                        <a href={tier.hostpage}>{tier.buttonText}</a>  
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    );
}