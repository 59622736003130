import React from 'react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: "120px"
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

function Contact() {
    const classes = useStyles();

    return (
        <div>
            <h1>Contact Details</h1>
            <Typography>
                Email: <a href="mailto:enquiries@infodraft-tech.com" className={classes.link}>enquiries@infodraft-tech.com</a>
            </Typography>
        </div>
    )
}

export default Contact;