import React from 'react'
import CustomGrid from '../components/CustomGrid';
import Hosting from './Hosting';

function Home() {
    return (
        <div>
            <Hosting />
            <CustomGrid />
        </div>
    )
}

export default Home;