import React from 'react';
import { GridList, GridListTile, Card, CardHeader, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    content: {
        flexGrow: 1,
        padding: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        trnasition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const tileData = [  
    {
        title: 'Promotions',
        path: '/hosting',
    },
    {
        title: 'Hosting Services',
        path: '/hosting',
    },
    {
        title: 'IT Infrastructure Services',
        path: '/',
    },
    {
        title: 'Network Solutions',
        path: '/',
    },
    {
        title: 'IT Products',
        path: '/',
    },
    {
        title: 'IT Support',
        path: '/',
    },
]

function CustomGrid() {
    const classes = useStyles();

    return (
        <div>
            <Container maxWidth="md" component="main">
            <div className={classes.root}>
                <GridList className={classes.gridList} cols={2} spacing={30} style={{ height: 'auto' }}>
                    {tileData.map(tile => (
                        <GridListTile key={tile.title} cols={1} className={classes.gridTile} style={{ height: 'auto' }}>
                            <Card>
                                <CardHeader title={tile.title} />
                                <CardActions>
                                    <Button size="small" color="primary" variant="outlined">
                                        <Link to={tile.path} className={classes.link}>Read more</Link>
                                    </Button>
                                </CardActions>
                            </Card>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
            </Container>

        </div>
    );
}

export default CustomGrid;